import React from "react"
import styled from "styled-components"

import Image from "./image"
import { profilePictureURLFromEmail } from "../helpers/functions"

const ProfileImage = styled(Image)`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: ${props => `${props.size}px`};
  background-color: lightgray;
  border: 1px solid lightgray;
`

export const ProfilePicture = ({ size, email, alt }) => (
  <ProfileImage
    source={profilePictureURLFromEmail(email)}
    alt={`${alt}`}
    size={size}
  />
)
