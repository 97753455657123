import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Page from "../components/page"
import PageHero from "../components/page-hero"
import { ProfilePicture } from "../components/author"
import {
  ArticlePublishedDate,
  ArticleTitleLarge,
} from "../components/blog-elements"

import { SectionLarge } from "../components/elements"
import { TwoColumnsGrid, FlexContainerVertical } from "../components/containers"
import BlogPostsGrid from "../components/blog-posts-grid"
import Comments from "../components/comments"
import NewsletterForm from "../components/newsletter-form"

import "../components/blog.css"
import "katex/dist/katex.min.css"

const ArticleMetadata = styled(TwoColumnsGrid)`
  grid-template-columns: auto auto !important;
  justify-content: start;
  align-items: center;
  grid-gap: 16px;

  margin-top: 24px;

  h4 {
    font-weight: normal;
  }
`

const BlogPostPageHeader = ({ cover, coverAlignment, date, title, author }) => (
  <PageHero cover={cover} coverAlignment={coverAlignment} title={title}>
    <FlexContainerVertical style={{ width: "100%", maxWidth: "1160px" }}>
      <section className="text-light text-shadow">
        <ArticleTitleLarge className="blog-post__title heading text-light">
          {title}
        </ArticleTitleLarge>
        <ArticleMetadata>
          <ProfilePicture size={40} email={author.email} alt={author.name} />
          <FlexContainerVertical style={{ lineHeight: "1 !important" }}>
            <strong style={{ lineHeight: "1" }}>{author.name}</strong>
            <em>
              <ArticlePublishedDate
                className="blog-post__date text-light"
                style={{ margin: 0 }}
                date={date}
              />
            </em>
          </FlexContainerVertical>
        </ArticleMetadata>
      </section>
    </FlexContainerVertical>
  </PageHero>
)

const RelatedArticlesSection = ({ posts }) => {
  if (!posts || posts.length < 1) {
    return null
  }
  return (
    <SectionLarge>
      <h2>Articoli correlati</h2>
      <BlogPostsGrid posts={posts} featureLatest={false} />
    </SectionLarge>
  )
}

export default function BlogPostPageTemplate({ data }) {
  const { post, coverData, relatedPostsData } = data
  const { relatedPosts } = relatedPostsData
  const { fields, metadata, html, excerpt } = post
  const { title } = metadata
  const { date, slug } = fields
  const imageContainer = coverData.edges[0] || {
    node: { childImageSharp: { fluid: {} } },
  }
  return (
    <Page
      title={title}
      description={excerpt}
      imageURL={imageContainer.node.childImageSharp.fluid.src}
      meta={[
        {
          name: `date`,
          content: date,
        },
      ]}
      slug={slug}
      heroContent={<BlogPostPageHeader {...metadata} {...fields} />}
    >
      <section className="blog-post__container">
        <div className="blog-post">
          <article
            className="blog-post__content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </section>
      <SectionLarge>
        <NewsletterForm />
      </SectionLarge>
      <section>
        <Comments {...metadata} {...fields} />
      </section>
      <RelatedArticlesSection posts={relatedPosts} />
    </Page>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!, $cover: String!, $tags: [String]!) {
    coverData: allFile(filter: { relativePath: { eq: $cover } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $path } }) {
      html
      ...BlogPost
    }
    relatedPostsData: allMarkdownRemark(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fields: { slug: { ne: $path } }
        frontmatter: { tags: { in: $tags } }
      }
    ) {
      relatedPosts: nodes {
        ...BlogPost
      }
    }
  }
`
